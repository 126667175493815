
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'


export default defineComponent({
   setup() {
       const store = useStore();

       const openModal = computed(() => {
           return store.getters.getModal
       })

       const openModalValue = computed(() => {
           return store.getters.getModalValue
       })

       const openModalLoading = computed(() => {
           return store.getters.getModalLoading
       })

       return {
           openModal,
           openModalValue,
           openModalLoading
       }
   }

})
